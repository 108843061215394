import { Loader } from "components/loader/loader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import { URLS } from "utils/urls";
import "./news_card.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const NewsCard = ({ imgUrl, title, date, pageUrl }) => {
  const navigate = useNavigate();
  const [isPdf, setIsPdf] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ setNumPages] = useState(null);

  useEffect(() => {
    checkFileType(imgUrl);
  }, [imgUrl]);

  function checkFileType(url) {
    const extension = url.split(".").pop().toLowerCase();
    setIsPdf(extension === "pdf");
  }

  // console.log(isPdf);

  return (
    <div className="newsCard" onClick={() => navigate(pageUrl)}>
      {isPdf ? (
        <div className="pdf-container">
          {loading && ( <div className="loader-container"> <Loader size={40} /> </div> )}
          <Document file={{ url: imgUrl, withCredentials: true }} onLoadSuccess={({ numPages }) => { setNumPages(numPages); setLoading(false); }} onLoadError={() => setLoading(false)} >
            <Page pageNumber={1} width={250} />
          </Document>
        </div>
      ) : (
        <img src={imgUrl || URLS.paySprintLogo} alt="news info" />
      )}

      <div className="info">
        <h4> {title} </h4>
        <em> {date.toDateString()} </em>
      </div>

    </div>
  );
};
