import "./home.scss";

import { Header } from "components/header/header";
import paySprintLogo from "assets/images/paysprint_logo.jpg";
import { NewsList } from "components/news_list/news_list";
import { Footer } from "components/footer/footer";
import { useLocation } from "react-router-dom";

export const Home = () => {

  const location = useLocation();
  const pathname = location.pathname;

  return (
    <div>
      <Header />
      
      <section className="hero">
        <img src={paySprintLogo} alt="paysprint logo" />

        {pathname !== '/opportunities' ? 
          <div className="right">
            <h1> Welcome to <span>Investors News</span> </h1>
            <p> "Fastest and affordable method of sending and receiving money, paying invoice and getting Paid at any time!" </p>
          </div>
        : 
          <div className="right">
            <h1> Welcome to <span> Investor Opportunities </span> </h1>
            <p> "Fastest and affordable method of sending and receiving money, paying invoice and getting Paid at any time!" </p>
          </div>
        }
      </section>

      <NewsList />
      <Footer />
    </div>
  );
};
