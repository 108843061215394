import axios from "axios";
import { NewsCard } from "components/news_card/news_card";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "./news_list.scss";
import { Loader } from "components/loader/loader";
import { useLocation } from "react-router-dom";
import { getUser } from "utils/utils";
import { URLS } from "utils/urls";





export const NewsList = () => {
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currPage, setCurrPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState("Something went wrong");
  const [oppotunities, setOppotunities] = useState([]);
  const [isLoadingOpp, setIsLoadingOpp] = useState(false);
  const [currPageOpp, setCurrPageOpp] = useState(0);
  const [totalPagesOpp, setTotalPagesOpp] = useState(0);
  const loggedInUser = getUser();
  


  const location = useLocation();
  const pathname = location.pathname;


  const getNewsOfPage = (page) => {
    setError("");
    setIsLoading(true);
    axios
      .get(`https://paysprint.ca/api/v1/investorsnews?page=${page}`, {
        headers: {
          Authorization:
            "Bearer base64:JFM+PJaWD/pBypX+NhXudDrAmianZdGYZ41qz4WhXL0=",
        },
      })
      .then((resp) => {
        setCurrPage(resp.data.data.current_page);
        setTotalPages(resp.data.data.last_page);
        setNews(resp.data.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong");
      });
  };

  const getOppotunitiesOfPage = (page) => {
    setError("");
    setIsLoadingOpp(true);
    axios
      .get(`https://paysprint.ca/api/v1/investor-opportunities?page=${page}`, {
        headers: {
          Authorization:
            "Bearer base64:JFM+PJaWD/pBypX+NhXudDrAmianZdGYZ41qz4WhXL0=",
        },
      })
      .then((resp) => {
        setCurrPageOpp(resp.data.data.current_page);
        setTotalPagesOpp(resp.data.data.last_page);
        setOppotunities(resp.data.data.data);
        setIsLoadingOpp(false);
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong");
      });
  };

  useEffect(() => {
    getNewsOfPage(1);
    getOppotunitiesOfPage(1);
  }, []);



  return ( <>
    <section className={pathname === '/opportunities' ? 'd-none' : 'newsList'} id="newslist">
      <h3> Our Latest News </h3>
      {error ? (
        <div className="error">
          <h3>{error}</h3>  
          <button onClick={() => getNewsOfPage(currPage || 1)}> Reload </button>
        </div>
      ) : isLoading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : news.length ? (
        <div className="container">
          {news.map((iNews, idx) => (
            <NewsCard key={iNews.id} title={iNews.title} date={new Date(iNews.created_at)} pageUrl={`/blog/${iNews.id}`} imgUrl={iNews.file} />
          ))}
        </div>
      ) : (
        <></>
      )}

      {totalPages ? (
        <div className="pagination">
          <button className="left" onClick={() => getNewsOfPage(currPage - 1)} disabled={currPage === 1}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          {new Array(totalPages).fill(0).map((_, idx) => (
            <button key={idx} disabled={currPage === idx + 1} onClick={() => getNewsOfPage(idx + 1)} className={`${currPage === idx + 1 ? "active" : ""}`}>
              {idx + 1}
            </button>
          ))}
          <button className="right" onClick={() => getNewsOfPage(currPage + 1)} disabled={currPage === totalPages}>
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      ) : (
        <></>
      )}
    </section>

      {loggedInUser?.email_verified === 1 ? 
        <section className="investor-relation-files" id="oppotunitylist">
          <h3 className="invest_h3"> Our Opportunities </h3>
          {error ? (
            <div className="error">
              <h3>{error}</h3>
              <button onClick={() => getOppotunitiesOfPage(currPageOpp || 1)}> Reload </button>
            </div>
          ) : isLoadingOpp ? (
            <div className="loader-container">
              <Loader />
            </div>
          ) : oppotunities.length ? (
            <div className="container">
              {oppotunities.map((oppotunity, idx) => (
                <NewsCard key={oppotunity.id} title={oppotunity.post_title} date={new Date(oppotunity.created_at)} pageUrl={`/opportunities/${oppotunity.id}`} imgUrl={URLS.paySprintLogo} />
              ))}
            </div>
          ) : (
            <></>
          )}

          {totalPagesOpp ? (
            <div className="pagination">
              <button className="left" onClick={() => getOppotunitiesOfPage(currPageOpp - 1)} disabled={currPageOpp === 1}>
                <FontAwesomeIcon icon={faAngleLeft} />
              </button>
              {new Array(totalPagesOpp).fill(0).map((_, idx) => (
                <button key={idx} disabled={currPageOpp === idx + 1} onClick={() => getOppotunitiesOfPage(idx + 1)} className={`${currPageOpp === idx + 1 ? "active" : ""}`}>
                  {idx + 1}
                </button>
              ))}
              <button className="right" onClick={() => getOppotunitiesOfPage(currPageOpp + 1)} disabled={currPageOpp === totalPagesOpp}>
                <FontAwesomeIcon icon={faAngleRight} />
              </button>
            </div>
          ) : (
            <></>
          )}
        </section>
        :  pathname === "/opportunities" ? (
            <section className="investor-relation-files" id="oppotunitylist">
              <em style={{ textAlign: "center", fontSize: "20px" }}>
                Please <b style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#exampleModal"> CLICK HERE </b> to login into your account and view our investment opportunities
              </em>
            </section>
          ) : null
      }

  </>);
};
