import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import "./footer.scss";

export const Footer = () => {
  return (
    <footer>
      <div className="group">
        <section className="about">
          <h4>ABOUT US</h4>
          <p>
            PaySprint is the fastest and most affordable way to send and receive
            money. Pay invoices and get paid at anytime!
          </p>
        </section>
        <section className="contact-clone">
          <h4>CONTACT US</h4>
          <ul>
            <li>
              <a className="contact-link" href="mailto:info@paysprint.ca">
                <FontAwesomeIcon icon={faEnvelope} />
                info@paysprint.ca
              </a>
            </li>
            <li>
              <a href="https://maps.app.goo.gl/JEMARZe4bpM7oxpLA" className="contact-link" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faLocationDot} />
                PaySprint Inc, <br /> WaterPark Place, 20 Bay Street, 11th Floor, Toronto, ON M5J 2N8 Canada
              </a>
            </li>
          </ul>
        </section>
        <section className="quickLink">
          <h4>QUICK LINK</h4>
          <ul>
            <li>
              <a href="https://paysprint.ca/about">About us</a>
            </li>
            <li>
              <a href="https://paysprint.ca/blog">Blog</a>
            </li>
            <li>
              <a href="https://paysprint.ca/contact">Contact Us</a>
            </li>
            <li>
              <a href="https://paysprint.ca/terms-of-service">Terms of Use</a>
            </li>
            <li>
              <a href="https://paysprint.ca/privacy-policy">Privacy Policy</a>
            </li>
            <li>
              <a href="https://paysprint.ca/pricing">Pricing</a>
            </li>
            <li>
              <a href="https://paysprint.ca/login">Login</a>
            </li>
            <li>
              <a href="https://paysprint.ca/register">Sign Up for FREE</a>
            </li>
          </ul>
        </section>
        <section className="services">
          <h4>SERVICES</h4>
          <ul>
            <li>
              <a href="https://paysprint.ca/payorganization?type=bG9jYWw=">
                Money Transfer
              </a>
            </li>
            <li>
              <a href="https://paysprint.ca/invoice">Pay Invoice</a>
            </li>
            <li>
              <a href="https://paysprint.ca/mywallet">Wallet</a>
            </li>
          </ul>
        </section>
        <section className="finance-info-clone">
          <h4>Finance Info</h4>
          <a
            className="finance-link"
            href="https://www10.fintrac-canafe.gc.ca/msb-esm/public/msb-search/name-search-results/7b227072696d617279536561726368223a7b226f72674e616d65223a22706179737072696e74222c2273656172636854797065223a317d7d/"
          >
            Canada: FINTRAC Reg No: M21469983
          </a>
          <a
            className="finance-link"
            href="https://www.fincen.gov/msb-registrant-search"
          >
            USA: FINCEN Reg No: 31000189515339
          </a>
        </section>
      </div>
      <section className="contact">
        <h4>CONTACT US</h4>
        <ul>
          <li>
            <a className="contact-link" href="mailto:info@paysprint.ca">
              <FontAwesomeIcon icon={faEnvelope} />
              info@paysprint.ca
            </a>
          </li>
          <li>
            <a className="contact-link">
              <FontAwesomeIcon icon={faLocationDot} />
              PaySprint by Express Ca Corp, 10 George St. North, Brampton. ON.
              L6X1R2. Canada
            </a>
          </li>
        </ul>
      </section>
      <section className="finance-info">
        <a
          className="finance-link"
          href="https://www10.fintrac-canafe.gc.ca/msb-esm/public/msb-search/name-search-results/7b227072696d617279536561726368223a7b226f72674e616d65223a22706179737072696e74222c2273656172636854797065223a317d7d/"
        >
          Canada: FINTRAC Reg No: M21469983
        </a>
        <a
          className="finance-link"
          href="https://www.fincen.gov/msb-registrant-search"
        >
          USA: FINCEN Reg No: 31000189515339
        </a>
      </section>
      <section className="footnote">
        <em>&copy; 2019 - 2023 Copyright, All Right Reserved</em>
      </section>
    </footer>
  );
};
