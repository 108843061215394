import { BlogPage } from "pages/blog/blog";
import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/home/home";
import { OpportunityPage } from "pages/oppotunities/oppotunity";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/opportunities" element={<Home />} />
      <Route exact path="/blog/:blogId" element={<BlogPage />} />
      <Route exact path="/opportunities/:oppotunityId" element={<OpportunityPage />} />
    </Routes>
  );
}

export default App;
