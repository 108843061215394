import "./oppotunity.scss";
import { Footer } from "components/footer/footer";
import { Header } from "components/header/header";
import { URLS } from "utils/urls";
import { NewsList } from "components/news_list/news_list";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Loader } from "components/loader/loader";
import axios from "axios";
import icon404 from "assets/images/404.png";
import parse from "html-react-parser";
import * as DOMPurify from "dompurify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { getUser, isLoggedIn } from "utils/utils";



export const OpportunityPage = () => {
  const { oppotunityId } = useParams();
  const [oppotunity, setOppotunity] = useState(null);
  const [downloadAbleFile, setDownloadAbleFile] = useState(null);
  const [doesNewsExist, setDoesNewsExist] = useState(null);
  const [error, setError] = useState("");
  const [oppotunityImageUrl, setOppotunityImageUrl] = useState("");
  const loggedInUser = getUser();




  const getSpecificOppotunity = () => {
    setDoesNewsExist(null);
    setError("");
    setOppotunity(null);
    axios
      .get(`https://paysprint.ca/api/v1/specific-opportunity/${oppotunityId}`, {
        headers: {
          Authorization:
            "Bearer base64:JFM+PJaWD/pBypX+NhXudDrAmianZdGYZ41qz4WhXL0=",
        },
      })
      .then((resp) => {
        setOppotunity(resp.data.data.data);
        setDownloadAbleFile(resp.data.data);
        checkIfImageExists(resp.data.data.data.investment_document);
        setDoesNewsExist(!!resp.data.data.data);
      })
      .catch((err) => {
        setError("Something went wrong");
        console.log(err);
      });
  };

  useEffect(() => { getSpecificOppotunity(); window.scrollTo(0, 0); }, [oppotunityId]);

  function checkIfImageExists(url) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      setOppotunityImageUrl(url);
    } else {
      img.onload = () => {
        setOppotunityImageUrl(url);
      };

      img.onerror = () => {
        setOppotunityImageUrl(URLS.paySprintLogo);
      };
    }
  }

  const downloadFile = (fileUrl) => {
    if (!fileUrl) { Swal.fire({ icon: "error", title: "Error", text: "Sorry the file you're requesting was not found.", }); return; }

    if (loggedInUser && loggedInUser.name) {
      window.open(downloadAbleFile.download_link, "_blank");
    } else {
      Swal.fire({ icon: "error", title: "Try again", text: "Please login using your Investor account and try again.", });
    }
  }

  
  
  return (
    <div className="opportunity-container">
      <Header />
      {error ? (
        <div className="error">
          <h3>{error}</h3>
          <button onClick={getSpecificOppotunity}> Reload </button>
        </div>
      ) : doesNewsExist === false ? (
        <img className="error" src={icon404} alt="404 error" />
      ) : oppotunity ? (
        <>
          {oppotunityImageUrl ? (
            <img src={oppotunityImageUrl} alt="paysprint logo" style={{ width: '45%' }} />
          ) : (
            <div className="loader-container"> <Loader size={40} /> </div>
          )}

          {loggedInUser && loggedInUser.email_verified === 1 ? (
            <div className="content">
              <h1>{oppotunity.post_title}</h1>
              <table>
                <tbody>
                  <tr>
                    <td> <b> Ref: </b> </td>
                    <td> {oppotunity.ref_code} </td>
                  </tr> 
                  <tr>
                    <td> <b> Published on: </b> </td>
                    <td> {new Date(oppotunity.created_at).toDateString()} </td>
                  </tr> <tr>
                    <td> <b> Investment Activation Date: </b> </td>
                    <td> {new Date(oppotunity.investment_activation_date).toDateString()} </td>
                  </tr> 
                  <tr>
                    <td> <b> Minimum Account: </b> </td>
                    <td> {oppotunity.minimum_acount} </td>
                  </tr> 
                  
                  <tr>
                    <td> <b> Liquidation Amount: </b> </td>
                    <td> {oppotunity.liquidation_amouunt} </td>
                  </tr>

                  <tr>
                    <td> <b> Offer Open Date: </b> </td>
                    <td> {oppotunity.offer_open_date} </td>
                  </tr>

                  <tr>
                    <td> <b> Offer End Date: </b> </td>
                    <td> {oppotunity.offer_end_date} </td>
                  </tr>

                  <tr>
                    <td> <b> Locked in Return: </b> </td>
                    <td> {oppotunity.locked_in_return} </td>
                  </tr>

                  <tr>
                    <td> <b> Terms: </b> </td>
                    <td> {oppotunity.term === '0' ? 'No terms' : oppotunity.term} </td>
                  </tr>

                  <tr>
                    <td> {isLoggedIn ? ( <button type="button" onClick={() => downloadFile(oppotunity.investment_document) }> View Attachment <FontAwesomeIcon icon={faDownload} /> </button> ) : <> <p> Please login to download the attached file </p> </> } </td>
                  </tr>
                </tbody>
              </table>

              <p> {parse(DOMPurify.sanitize(loggedInUser ? oppotunity.description : oppotunity.description.slice(0, 500) + ".....<br /> <br /> <span> Please <a href{'#login'} data-bs-toggle='modal' data-bs-target='#exampleModal'> login </a> to read the full article </>"))} </p>
            </div>
          ) : <div className="content" style={{ display: 'flex', flexDirection: 'column' }}> 
                <em style={{ textAlign: 'center', fontSize: '20px', alignSelf: 'center' }}> 
                  Please Login and <b> VERIFY </b> your account with us to see our investment oppotunities 
                </em> 

                <div className="not-loggedIn">
                  <button type="button" data-bs-toggle="modal" data-bs-target="#registrationModal"> Register Here </button>
                  {loggedInUser ? <button type="button" style={{ background: 'blue' }}> Resend Verification Link </button> : null }
                  <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"> Login Here </button>
                </div>
              </div> }
        </>
      ) : (
        <div className="loader-container">
          <Loader size={70} />
        </div>
      )}

      <NewsList />
      <Footer />
    </div>
  );
};
