import { useLocation } from "react-router-dom";

export const STATES = {
  loading: "loading",
  success: "success",
  error: "error",
};


export const isLoggedIn = () => {
  const authData = localStorage.getItem("authData");
  if (!authData) return false;

  const parsedAuthData = JSON.parse(authData);
  const currentTime = new Date().getTime();
  if (!parsedAuthData || !parsedAuthData.expirationTime) return false;

  return parsedAuthData.expirationTime > currentTime; // Check if token is still valid
};

export const getUser = () => {
  const user = localStorage.getItem("user");
  
  if (user) {
    const parsedUser = JSON.parse(user);
    // console.log(parsedUser);
    return parsedUser;
  }

  return null; 
};


export const GetMyUrl = () => {
  const location = useLocation();
  const pathname = location.pathname;

  return pathname;
}