import "./blog.scss";
import { Footer } from "components/footer/footer";
import { Header } from "components/header/header";
import { URLS } from "utils/urls";
import { NewsList } from "components/news_list/news_list";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Loader } from "components/loader/loader";
import axios from "axios";
import icon404 from "assets/images/404.png";
import parse from "html-react-parser";
import * as DOMPurify from "dompurify";

export const BlogPage = () => {
  const { blogId } = useParams();
  const [news, setNews] = useState(null);
  const [doesNewsExist, setDoesNewsExist] = useState(null);
  const [error, setError] = useState("");
  const [blogImageUrl, setBlogImageUrl] = useState("");



  const getNews = () => {
    setDoesNewsExist(null);
    setError("");
    setNews(null);
    axios
      .get(`https://paysprint.ca/api/v1/specificnews/${blogId}`, {
        headers: {
          Authorization:
            "Bearer base64:JFM+PJaWD/pBypX+NhXudDrAmianZdGYZ41qz4WhXL0=",
        },
      })
      .then((resp) => {
        setNews(resp.data.data.data);
        checkIfImageExists(resp.data.data.data.investment_document);
        setDoesNewsExist(!!resp.data.data.data);
      })
      .catch((err) => {
        setError("Something went wrong");
        console.log(err);
      });
  };

  useEffect(() => { getNews(); window.scrollTo(0, 0); }, [blogId]);

  function checkIfImageExists(url) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      setBlogImageUrl(url);
    } else {
      img.onload = () => {
        setBlogImageUrl(url);
      };

      img.onerror = () => {
        setBlogImageUrl(URLS.paySprintLogo);
      };
    }
  }

  
  return (
    <div className="blog-container">
      <Header />
      {error ? (
        <div className="error">
          <h3>{error}</h3>
          <button onClick={getNews}> Reload </button>
        </div>
      ) : doesNewsExist === false ? (
        <img className="error" src={icon404} alt="404 error" />
      ) : news ? (
        <>
          {blogImageUrl ? (
            <img src={blogImageUrl} alt="paysprint logo" style={{ width: '45%' }} />
          ) : (
            <div className="loader-container"> <Loader size={40} /> </div>
          )}

          <div className="content">
            <h1> {news.title} </h1>
            <em> Published on {new Date(news.created_at).toDateString()} </em>
            <p> {parse(DOMPurify.sanitize(news.description))} </p>
          </div>
        </>
      ) : (
        <div className="loader-container">
          <Loader size={70} />
        </div>
      )}

      <NewsList />
      <Footer />
    </div>
  );
};
